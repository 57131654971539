import React, { useState, useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { IoSearch } from "react-icons/io5";
import { LuTimer } from "react-icons/lu";
import { FiMapPin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import MapContainer from "../../components/MapContainer";
import { get, post } from "../../components/api";
import moment from "moment";
import "./styles.css";
import Loading from "../../components/Loading";
import Translate from "../../components/Translate";
import DialogBox from "../../components/DialogBox";
import { VscDebugDisconnect } from "react-icons/vsc";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedVehicleNo, setSelectedVehicleNo] = useState("");
  const [selectVehicleNo, setSelectVehicleNo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [vehicleLocations, setVehicleLocations] = useState([]);
  const [trackVehicle, setTrackVehicle] = useState([]);
  // const [fuelConsumption, setFuelConsumption] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [activeVehicleList, setActiveVehicleList] = useState([]);
  const [vehicleStatus, setVehicleStatus] = useState({
    offline: 0,
    idle: 0,
    moving: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tagnames, setTagNames] = useState([]);
  const [isSmallScreen] = useState(window.innerWidth < 480);

  let userData = localStorage.getItem("ftmUserData");

  if (userData) {
    try {
      userData = JSON.parse(userData);
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  } else {
    console.log("No user data found in localStorage");
    navigate("/login");
  }

  const handleTrackVehicle = async () => {
    const apiResponse = await get("/admin/all-vehicle-current-loc");
    if (apiResponse.status == 200) {
      let locations = [];
      let res = apiResponse.data;
      if (res.length > 0) {
        const statusCounts = { ...vehicleStatus };
        for (let location of res) {
          locations.push({
            lat: location.coordinates[0],
            lng: location.coordinates[1],
            vehicleStatus: location.vehicle.tripVehicleStatus,
            vehicleNo: location.vehicleNo,
            address: location.address,
            id: location._id,
            show: false,
            speed: location.speed,
          });
          if (location.vehicle.tripVehicleStatus) {
            if (statusCounts[location.vehicle.tripVehicleStatus]) {
              statusCounts[location.vehicle.tripVehicleStatus]++;
            } else {
              statusCounts[location.vehicle.tripVehicleStatus] = 1;
            }
          }
        }
        setVehicleStatus(statusCounts);
      }
      setVehicleLocations(locations);
      setTrackVehicle(locations);
    } else {
      setTrackVehicle([]);
    }
  };
  const handleActiveVehicle = async () => {
    setLoading(true);
    const apiResponse = await post("/get-most-active-vehicle", {});
    if (apiResponse.status == 200) {
      setActiveVehicleList(apiResponse.data);
      setVehicleList(apiResponse.data);
      setLoading(false);
    } else {
      setActiveVehicleList([]);
      setVehicleList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleTrackVehicle();
    handleActiveVehicle();
    setIsLoading(false);
  }, []);

  const filterVehiclesByVehicleNo = (vehicles, searchString) => {
    const lowerCaseSearchString = searchString.toLowerCase();
    const searchedVehicle = vehicles.filter((vehicle) =>
      vehicle?.vehicleNo?.toLowerCase().includes(lowerCaseSearchString)
    );
    const searchedVehicleByTag = vehicles.filter((vehicle) =>
      vehicle?.tags?.some((tag) => tag.tagname.includes(lowerCaseSearchString))
    );
    const uniqueItems = [...searchedVehicle, ...searchedVehicleByTag].filter(
      (item, index) =>
        [...searchedVehicle, ...searchedVehicleByTag].indexOf(item) === index
    );
    return uniqueItems;
  };

  const searchVehicle = (event) => {
    if (event.target.value !== "") {
      const filteredVehicles = filterVehiclesByVehicleNo(
        activeVehicleList,
        event.target.value
      );
      setActiveVehicleList(filteredVehicles);
    } else {
      setActiveVehicleList(vehicleList);
    }
  };

  const IconWithAddress = ({ vehicle }) =>
    vehicle?.address && (
      <div className="d-flex align-items-start">
        <FiMapPin style={{ fontSize: "1em" }} />{" "}
        {/* Adjust the size as needed */}
        <span className="ml-2 today-km w-100">{vehicle?.address}</span>
      </div>
    );

  const handleCloseModal = async () => {
    setModalShow(false); // Close the dialog box
    await handleActiveVehicle(); // Refresh vehicle info after closing dialog
  };

  const handleAddTag = () => {
    handleCloseModal();
  };

  const addOrUpdateTag = (event, id, number) => {
    event.stopPropagation();
    const selectedVehicle = activeVehicleList?.find(
      (vehicle) => vehicle._id === id
    );
    setTagNames(selectedVehicle.tags);
    setSelectedVehicle(id);
    setSelectedVehicleNo(number);
    setModalShow(true);
  };

  const updateTag = (id, vehicleId) => {
    const updatedTagname = tagnames.filter((tagname) => tagname._id !== id);
    setTagNames(updatedTagname);

    const updatedActiveVehicleList = activeVehicleList?.map((vehicle) => {
      if (vehicle._id === vehicleId) {
        const updatedTags = vehicle.tags.filter((tag) => tag._id !== id);
        return { ...vehicle, tags: updatedTags }; // Refresh vehicle info after deleting tag
      }
      return vehicle;
    });
    setActiveVehicleList(updatedActiveVehicleList);
  };

  const selectVehicle = (vehicleNo) => {
    setSelectVehicleNo(vehicleNo);
  };

  // fiilter vehicle by status
  const filtervehicleByStatus = (type) => {
    setSelectVehicleNo("");
    if (type === "all") {
      setTrackVehicle(vehicleLocations);
      setActiveVehicleList(vehicleList);
      // setSelectVehicleNo("");
    } else {
      const data = vehicleLocations.filter(
        (vehicle) => vehicle?.vehicleStatus === type
      );
      const vehiclesData = vehicleList.filter(
        (vehicle) => vehicle?.tripVehicleStatus === type
      );
      setTrackVehicle(data);
      setActiveVehicleList(vehiclesData);
      // setSelectVehicleNo("");
    }
  };
  return (
    <>
      {!isLoading ? (
        <>
          <div className="row dashboard">
            <div className="col-md-5 col-lg-4 col-xs-12 p-0">
              <div className="input-group mb-2">
                <span className="input-group-text" id="basic-addon1">
                  <IoSearch />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Vehicle"
                  aria-label="Vehicle"
                  aria-describedby="basic-addon1"
                  onChange={searchVehicle}
                />
              </div>
              <div className="vehicle-list-parent">
                {activeVehicleList.length <= 0 && loading && <Loading />}
                {activeVehicleList.length <= 0 && !loading && (
                  <div className="">No vehicles found</div>
                )}
                {activeVehicleList.length > 0 &&
                  !loading &&
                  activeVehicleList?.map((vehicle, index) => (
                    <div
                      key={index}
                      className={
                        vehicle.vehicleNo == selectVehicleNo
                          ? `card text-center mb-2 selected-vehicle cursor-pointer`
                          : `card text-center mb-2 cursor-pointer`
                      }
                      onClick={() => selectVehicle(vehicle.vehicleNo)}
                    >
                      <div className="card-body">
                        <div className="vehicle-details">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="mb-1 vehicle-no">
                              {vehicle?.vehicleNo}
                            </p>
                            {vehicle.wireCut ? (
                              <div
                                style={{
                                  backgroundColor: "#fc6767",
                                  padding: "2px",
                                  borderRadius: "6px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  paddingInline: "4px",
                                }}
                              >
                                <p
                                  className="vehicleStatusText"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <VscDebugDisconnect
                                    color="white"
                                    size={"14px"}
                                    style={{ marginRight: "5px" }}
                                  />
                                  <span style={{ fontWeight: "500" }}>
                                    Wirecut
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="d-flex align-items-start">
                            <LuTimer size={16} />
                            <p className="mb-1 ml-2 today-km">
                              {vehicle?.todayKilometers} km{" "}
                              <Translate>travelledToday</Translate> -{" "}
                              {moment(vehicle?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </p>
                          </div>
                          <IconWithAddress vehicle={vehicle} />
                        </div>
                      </div>
                      <div className="card-footer text-muted">
                        <Stack direction="horizontal" gap={2}>
                          <Badge
                            bg={
                              vehicle.tripVehicleStatus == "moving"
                                ? "success"
                                : vehicle.tripVehicleStatus == "idle"
                                ? "warning"
                                : "danger"
                            }
                          >
                            {" "}
                            <Translate>{vehicle.tripVehicleStatus}</Translate>
                          </Badge>
                          {vehicle?.tags?.map((tag, i) => (
                            <Badge key={i}>{tag.tagname}</Badge>
                          ))}
                          <Badge
                            bg="secondary"
                            onClick={(event) =>
                              addOrUpdateTag(
                                event,
                                vehicle._id,
                                vehicle.vehicleNo
                              )
                            }
                            // className="ms-auto"
                          >
                            {" "}
                            <Translate>addtag</Translate>
                          </Badge>
                        </Stack>
                      </div>
                    </div>
                  ))}
                <DialogBox
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  vehicleId={selectedVehicle}
                  vehicleNo={selectedVehicleNo}
                  tags={tagnames}
                  handleAddTag={handleAddTag}
                  updateTag={updateTag}
                />
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xs-12">
              <div className="row h-100">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="card custom-card h-100">
                    <div className="card-header d-flex justify-content-between">
                      <h6>
                        <Translate>map</Translate>
                      </h6>
                      <div className="d-flex">
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("all")}
                        >
                          <Translate>allVehicles</Translate> (
                          {vehicleList.length})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("moving")}
                        >
                          <Translate>running</Translate> (
                          {vehicleStatus?.moving})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("idle")}
                        >
                          <Translate>idle</Translate> ({vehicleStatus?.idle})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("offline")}
                        >
                          <Translate>offline</Translate> (
                          {vehicleStatus?.offline})
                        </a>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      {vehicleList.length > 0 ? (
                        <MapContainer
                          locations1={trackVehicle}
                          selectVehicleNo={selectVehicleNo}
                        />
                      ) : (
                        <div>No records found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Dashboard;
