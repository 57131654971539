import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, Badge } from "react-bootstrap";
import DataTablePagination from "../../components/Datatable";
import Download from "../../assets/images/invoice/download.png";
import Print from "../../assets/images/invoice/print.png";
import moment from "moment";
import { SweetAlert, getUserIdFromLC } from "../../helpers/helper";
import { post } from "../../components/api";

const Orders = () => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <BsThreeDotsVertical size={"20px"} color="#2A7C7A" />
    </a>
  ));

  CustomToggle.displayName = "CustomToggle";

  const downloadInvoice = async (orderId) => {
    try {
      const download = await post("/admin/invoice", { orderId: orderId });

      if (download.status === 200 && download.data?.url) {
        const fileResponse = await fetch(download.data.url);
        console.log(fileResponse);
        if (!fileResponse.ok) {
          throw new Error("Failed to fetch the invoice file.");
        }

        const blob = await fileResponse.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = `${orderId}_${Date.now()}.pdf` || "downloaded-file.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the object URL
        window.URL.revokeObjectURL(url);

        SweetAlert("Order invoice downloaded successfully", false);
        // .then((response) => response.blob())
        // .then((blob) => {
        //   const url = window.URL.createObjectURL(new Blob([blob]));
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.download = `${orderId}_${Date.now()}.pdf` || "downloaded-file.pdf";
        //   document.body.appendChild(link);

        //   link.click();

        //   document.body.removeChild(link);
        //   window.URL.revokeObjectURL(url);
        //   SweetAlert("Order invoice downloaded successfully", false);
        // })
        // .catch((error) => {
        //   console.error("Error fetching the file:", error);
        //   SweetAlert("Unable to download order invoice, Try again", true)
        // });
      } else {
        SweetAlert("Order invoice not found", true);
      }
    } catch (error) {
      console.error("Error:", error);
      SweetAlert("Unable to download order invoice. Please try again.", true);
    }
  };

  const handlePrintInvoice = async (orderId) => {
    const download = await post("/admin/invoice", { orderId: orderId });
    if (download.status == 200 && download.data != undefined) {
      window.open(download.data.url);
    }
  };

  const columns = [
    {
      name: "Transaction Id",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.plan?.type,
      sortable: true,
    },
    {
      name: "Subscription Type",
      selector: (row) => row.plan?.name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.razorpayStatus == "paid" ? (
          <div className="invoice-badge">
            <Badge bg="success">Paid</Badge>
          </div>
        ) : (
          <div className="invoice-badge">
            <Badge bg="danger">Failed</Badge>
          </div>
        ),
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.vehicleNo.length,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmoutWithGst,
      sortable: true,
    },
    {
      cell: (row) =>
        row.razorpayStatus == "paid" ? (
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggle}
              style={{ display: "block", marginBottom: "50px" }}
            />
            <Dropdown.Menu
              size="sm"
              title="Actions"
              className="action-dropdown"
              style={{
                position: "absolute",
                top: "80px", // Adjust height to position it above
                zIndex: 1000,
              }}
            >
              <Dropdown.Item>
                <div
                  className="d-flex align-items-center"
                  onClick={() => downloadInvoice(row.orderId)}
                >
                  <img src={Download} alt="" width={"20px"} height={"20px"} />
                  <span className="invoice-btn-text">Download</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div
                  className="d-flex align-items-center"
                  onClick={() => handlePrintInvoice(row.orderId)}
                >
                  <img src={Print} alt="" width={"20px"} height={"20px"} />
                  <span className="invoice-btn-text">Print</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null,
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="orders-page p-4">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="custom-datatable">
              <DataTablePagination
                title="Invoices"
                columns={columns}
                apiUrl="/admin/get-orderlist"
                queryObj={[{ client: getUserIdFromLC() }]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
