import React, { useEffect, useMemo } from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Dashboard from "../pages/Dashboard";
import Vehicles from "../pages/Vehicles";
import FleetReports from "../pages/Report";
import FleetReportDetails from "../pages/ReportDetails";
import Orders from "../pages/Orders";
import BuyGps from "../pages/BuyGps";
import BuyGpsFuel from "../pages/BuyGpsFuel";
import Faqs from "../pages/Faqs";
import Setting from "../pages/Setting";
import Support from "../pages/Support";
import Profile from "../pages/Profile";
import ProfileEdit from "../pages/ProfileEdit";
import TripCalculator from "../pages/TripCalculator";
import Documents from "../pages/Documents";
import Login from "../pages/login";
import RequestOtp from "../pages/RequestOtp";
import VehicleInfo from "../pages/VehicleInfo";
import VehiclePlayRoute from "../pages/VehicleRoute";
import Geofence from "../pages/Geofence";
import GeofenceDashboard from "../pages/GeofenceDashboard";
import FuelTheft from "../pages/FuelTheft";
import Alerts from "../pages/Alerts";
import User from "../pages/User";
import GeofenceEdit from "../pages/GeofenceEdit";
import SharePage from "../pages/Share";
const BaseRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  var currentLocation = window.location.pathname;
  const ftmUserData = localStorage.getItem("ftmUserData");

  useEffect(() => {
    // Redirect to login page
    const isSharePage = location.pathname.includes("/share");
    if (!ftmUserData && !isSharePage) {
      navigate("/login");
    } else if (currentLocation == "/" || currentLocation == "/login") {
      navigate("/dashboard");
    }
  }, []);

  const userData = useMemo(() => {
    return ftmUserData ? JSON.parse(ftmUserData) : null;
  }, [ftmUserData]);

  return (
    <>
      <div className="app-container">
        <Routes>
          {/* Protected Routes Start */}
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<User />} />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicles/:id" element={<VehicleInfo />} />
            <Route path="/playback/:id" element={<VehiclePlayRoute />} />
            <Route path="/geofence" element={<GeofenceDashboard />} />
            <Route exact path="/geofence/create" element={<Geofence />} />
            <Route exact path="/geofence/edit" element={<GeofenceEdit />} />
            <Route
              path="/fuel-theft"
              element={
                userData?.isFuel ? <FuelTheft /> : <Navigate to="/dashboard" replace />
              }
            />
            <Route path="/fleet-reports" element={<FleetReports />} />
            <Route
              path="/fleet-reports/:slug"
              element={<FleetReportDetails />}
            />
            <Route path="/orders" element={<Orders />} />
            <Route path="/plans/buy-gps" element={<BuyGps />} />
            <Route path="/plans/buy-gps-fuel" element={<BuyGpsFuel />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/trip-calculator" element={<TripCalculator />} />
            <Route path="/support" element={<Support />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/profile/edit/:id" element={<ProfileEdit />} />
            <Route path="/alerts" element={<Alerts />} />
          </Route>
          {/* Protected Routes End */}
          {/* Unprotected Routes Start */}
          <Route path="/share/:id" element={<SharePage />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/request-otp" element={<RequestOtp />} />
          {/* Unprotected Routes End */}
        </Routes>
      </div>
    </>
  );
};

export default BaseRoutes;
{
  /* <Route path="/e-challan" element={<EChalan />} /> */
}
{
  /* <Route exact path="/" element={<ProtectedRoute />}>
                {accessedRoutes.map((route,index) => (
                  <Route key={index} exact path={route.path} element={<route.component />} />
                ))}
              </Route> */
}
