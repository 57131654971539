import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ApexChart from "../../components/ApexChart";
import { post } from "../../components/api";
import moment from "moment";
import "./styles.css";
import Loading from "../../components/Loading";
import { SweetAlert } from "../../helpers/helper";
import ResponsiveTable from "../../components/ResponsiveTable";
import MapFuelFill from "../../components/MapFuelFill";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import ProgressBar from "../../components/ProgressBar";
import Select from "react-select";
import { RiTruckLine } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import Translate from "../../components/Translate";

const FuelTheft = () => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const navigate = useNavigate();
  const [fuelConsumption, setFuelConsumption] = useState([]);
  const [speedData, setSpeedData] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectVehicleNo, setSelectVehicleNo] = useState("");
  const [activeVehicleList, setActiveVehicleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fueldata, setFuelData] = useState([]);
  const [finalFuelData, setFinalFuelData] = useState([]);
  const [fuelLocation, setFuelLocation] = useState([]);
  const [startDate, setStart] = useState(moment().startOf("day").toDate());
  const [endDate, setEnd] = useState(moment().toDate());
  const [vehicleOption, setVehicleOption] = useState([]);
  const [reportSelect, setReportSelect] = useState({
    value: "Default",
    label: "Default",
  });
  const [vehicle, setVehicle] = useState("");
  const [downloadReportLink, setDownloadReportLink] = useState("");
  const [initialLoad, setIsInitialLoad] = useState(true);
  const [startDateWithTime, setStartDateWithTime] = useState(
    moment(startDate).format("DD/MM/YYYY HH:mm")
  );
  const [endDateWithTime, setEndDateWithTime] = useState(
    moment(endDate).format("DD/MM/YYYY HH:mm")
  );
  const [fuelAnnotations, setFuelAnnotations] = useState([]);
  const [tankCapacity, setTankCapacity] = useState(420);
  const today = moment().endOf("day");
  const now = moment();
  // let updatedpointData = [];
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#fff",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "0.8rem",
    }),
  };
  let userData = localStorage.getItem("ftmUserData");

  if (userData) {
    try {
      userData = JSON.parse(userData);
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  } else {
    console.log("No user data found in localStorage");
    navigate("/login");
  }
  const handleFuelStats = async () => {
    const apiResponse = await post("/fuel-level-stats-halfhour", {
      vehicleNo: selectVehicleNo,
      startDate: startDateWithTime,
      endDate: endDateWithTime,
    });
    if (apiResponse.status == 200) {
      const FuelDataArray = apiResponse?.data.fuelData;
      const CoordinatesArray = apiResponse?.data.coordinates;
      if (FuelDataArray.length > 0 && CoordinatesArray.length > 0) {
        const arrayOfObjects = apiResponse?.data?.coordinates?.map(
          ([lat, lng]) => ({
            lat,
            lng,
          })
        );
        const combinedArray = arrayOfObjects?.map((coords, index) => ({
          ...coords,
          address: FuelDataArray[index].Location,
          FuelConsumed: FuelDataArray[index].fuelFill,
          fuelTheft: FuelDataArray[index].fuelTheft,
        }));
        setFuelLocation(combinedArray);
      }
      setFuelConsumption(apiResponse.data.data);
      setSpeedData(apiResponse?.data?.data1);
      setFuelData(apiResponse?.data?.fuelData);
      // setFinalFuelData(apiResponse?.data?.fuelData.reverse());
      setFinalFuelData(
        apiResponse?.data?.fuelData
          ? [...apiResponse.data.fuelData].reverse()
          : []
      );
      setDownloadReportLink(apiResponse?.data.downloadlink);
      setIsLoading(false);
      setIsInitialLoad(false);
      apiResponse?.data.fuelData.filter((data) => data.fuelFill !== "NA");
      console.log(apiResponse.data.tankCapacity);
      setTankCapacity(apiResponse.data.tankCapacity);
    } else {
      setFuelConsumption([]);
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  };

  useEffect(() => {
    if (reportSelect.value === "Default") {
      const formattedFuelData = fueldata.map((item) => ({
        ...item,
        time: moment(item.time).format("DD-MM-YY HH:mm"),
      }));
      setFinalFuelData(formattedFuelData);
    } else if (reportSelect.value === "Fill") {
      const filterData = fueldata
        .filter((data) => data.fuelFill !== "NA")
        .map(({ time, ...rest }) => ({
          time: moment(time).format("DD-MM-YY HH:mm"),
          ...rest,
        }));
      setFinalFuelData(filterData.reverse());
    } else if (reportSelect.value === "Theft") {
      const filterData = fueldata
        .filter((data) => data.fuelTheft !== "NA")
        .map(({ time, ...rest }) => ({
          time: moment(time).format("DD-MM-YY HH:mm"),
          ...rest,
        }));
      setFinalFuelData(filterData.reverse());
    }
  }, [reportSelect]);

  const handleActiveVehicle = async () => {
    const apiResponse = await post("/get-most-active-vehicle", {});
    if (apiResponse.status == 200 && apiResponse.data.length > 0) {
      const filteredData = apiResponse.data.filter((vehicle) => vehicle.isFuel);
      setActiveVehicleList(filteredData);
      setVehicleList(filteredData);

      setSelectVehicleNo(filteredData[0].vehicleNo);
      setVehicle({
        value: filteredData[0].vehicleNo,
        label: filteredData[0].vehicleNo,
      });
    } else {
      setActiveVehicleList([]);
      setVehicleList([]);
    }
  };
  
  useEffect(() => {
    handleActiveVehicle();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    handleFuelStats();
  }, [selectVehicleNo, endDateWithTime, startDateWithTime]);

  const initialSettings = {
    startDate: startDate,
    endDate: endDate,
    timePicker: true,
    timePicker24Hour: false,
    alwaysShowCalendars: true,
    maxDate: today.toDate(),
    maxTime: now.format("HH:mm"),
    locale: {
      format: "MMM D, YY HH:mm", // 12-hour format
    },
    opens: "left",
    ranges: {
      Today: [moment().startOf("day"), moment()],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [moment().subtract(6, "days").startOf("day"), moment()],
      "Last 15 Days": [moment().subtract(14, "days").startOf("day"), moment()],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month").startOf("day"),
        moment().subtract(1, "month").endOf("month").endOf("day"),
      ],
    },
  };

  const handleApplyDate = (event, picker) => {
    if (!isLoading) {
      const selectedStart = picker.startDate;
      const selectedEnd = picker.endDate;

      if (selectedEnd.isBefore(selectedStart.clone().add(1, "months"))) {
        const startDateTime = moment(selectedStart).format("DD/MM/YYYY HH:mm");
        const endDateTime = moment(selectedEnd).format("DD/MM/YYYY HH:mm");
        setStartDateWithTime(startDateTime);
        setEndDateWithTime(endDateTime);
        setStart(startDateTime);
        setEnd(endDateTime);
      } else {
        SweetAlert(
          "Please select a date range within one month from the start date.",
          true
        );
      }
    }
  };

  const Lineseris = [
    {
      name: "Fuel",
      data: fuelConsumption?.reverse(),
    },
    {
      name: "Speed", // Second line
      data: speedData?.reverse(),
    },
  ];

  useEffect(() => {
    if (finalFuelData.length > 0) {
      const annotations = finalFuelData.map((point) => {
        let labelType = "";
        let value = "";
        if (reportSelect.value === "Default") {
          labelType =
            point?.fuelFill !== "NA"
              ? "Fuel Fill"
              : point?.fuelTheft !== "NA"
              ? "Fuel Theft"
              : "No Fuel Event";
          value =
            point?.fuelFill !== "NA"
              ? point?.fuelFill
              : point?.fuelTheft !== "NA"
              ? point?.fuelTheft
              : "NA";
        } else if (reportSelect.value === "Fill") {
          labelType = "Fuel Fill";
          value = point?.fuelFill;
        } else if (reportSelect.value === "Theft") {
          labelType = "Fuel Theft";
          value = point?.fuelTheft;
        }
        return {
          x: new Date(moment(point?.time).format("DD-MM-YY HH:mm")).getTime(),
          label: {
            text: `${labelType} ${value}`,
            style: {
              background: "#2A7C76",
              color: "#fff",
            },
          },
        };
      });

      setFuelAnnotations(annotations); // Update state
    }
  }, [finalFuelData, reportSelect.value]);

  // const getColors = (data) => {
  //   return data.map((value) => {
  //     return value.y > 50 ? "red" : "blue";
  //   });
  // };

  const LineOptions = {
    chart: {
      type: "area",
      stacked: false,
      height: 250,
      background: "white",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        autoSelected: "zoom",
      },
    },
    annotations: {
      points: fuelAnnotations,
    },

    colors: ["#2A7C76", "red"],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: "smooth",
      width: [1, 0.1],
    },
    yaxis: [
      {
        min: 0,
        max: tankCapacity,
        labels: {
          formatter: function (val) {
            return Math.round(val / 10) * 10;
          },
        },
        title: {
          text: "Diesel Level",
        },
      },
      {
        opposite: true,
        min: 0,
        max: 90,
        labels: {
          formatter: function (val) {
            return Math.round(val) + " km/h";
          },
        },
        title: {
          text: "Speed (km/h)",
        },
      },
    ],
    xaxis: {
      labels: {
        formatter: function (val) {
          return `${moment(val).format("HH")}:00`;
        },
      },
      type: "datetime",
    },
    tooltip: {
      shared: true,
      x: {
        formatter: function (val) {
          return moment(val).format("MMM D, YYYY HH:mm:ss");
        },
      },
      y: {
        formatter: function (val, { seriesIndex }) {
          return seriesIndex === 0 ? val + " Diesel" : val + " km/h";
        },
      },
    },
  };

  const filterVehiclesByVehicleNo = (vehicles, searchString) => {
    const lowerCaseSearchString = searchString.toLowerCase();
    const searchedVehicle = vehicles.filter((vehicle) =>
      vehicle?.vehicleNo?.toLowerCase().includes(lowerCaseSearchString)
    );
    const searchedVehicleByTag = vehicles.filter((vehicle) =>
      vehicle?.tags?.some((tag) => tag.tagname.includes(lowerCaseSearchString))
    );
    const uniqueItems = [...searchedVehicle, ...searchedVehicleByTag].filter(
      (item, index) =>
        [...searchedVehicle, ...searchedVehicleByTag].indexOf(item) === index
    );
    return uniqueItems;
  };

  const searchVehicle = (event) => {
    if (event.target.value !== "") {
      const filteredVehicles = filterVehiclesByVehicleNo(
        activeVehicleList,
        event.target.value
      );
      setActiveVehicleList(filteredVehicles);
    } else {
      setActiveVehicleList(vehicleList);
    }
  };

  const selectVehicle = (vehicleNo) => {
    console.log(vehicleNo);
    setSelectVehicleNo(vehicleNo);
  };

  useEffect(() => {
    setVehicleOption(createVehicleOption());
  }, [activeVehicleList]);

  const createVehicleOption = () => {
    return activeVehicleList.map((vehicle) => ({
      value: `${vehicle.vehicleNo}`,
      label: `${vehicle.vehicleNo}`,
      id: vehicle._id,
    }));
  };
  const handleVehicleNo = (vehicleObj) => {
    if (vehicle.value !== vehicleObj.value) {
      setVehicle(vehicleObj);
      selectVehicle(vehicleObj.value);
      const newArray = vehicleObj.value
        ? [
            activeVehicleList.find((v) => v.vehicleNo === vehicleObj.value),
            ...activeVehicleList.filter(
              (v) => v.vehicleNo !== vehicleObj.value
            ),
          ]
        : activeVehicleList;
      setActiveVehicleList(newArray);
    }
  };

  const reportoption = [
    {
      value: "Default",
      label: "Default",
    },
    {
      value: "Fill",
      label: "Fill",
    },
    {
      value: "Theft",
      label: "Theft",
    },
  ];

  const handleReport = (reportObj) => {
    setReportSelect(reportObj);
  };

  // const inputClick = (event) => {
  //   console.log(event);
  // };

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const response = await fetch(
        `${downloadReportLink}?nocache=${new Date().getTime()}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadLoading(false);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  return (
    <div className="dashboard">
      {initialLoad ? (
        <Loading />
      ) : (
        <>
          <div className="row mb-5">
            <div className="col-md-5 col-lg-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <IoSearch />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Vehicle"
                      aria-label="Vehicle"
                      aria-describedby="basic-addon1"
                      onChange={searchVehicle}
                    />
                  </div>
                  <div className="vehicle-list-parent-fuel">
                    {activeVehicleList.length <= 0 && !isLoading && (
                      <div className="" style={{ marginLeft: "15px" }}>
                        No vehicles found
                      </div>
                    )}
                    {activeVehicleList.length > 0 &&
                      activeVehicleList?.map((vehicle, index) => (
                        <div
                          className={
                            vehicle.vehicleNo == selectVehicleNo
                              ? `card text-center mb-2 selected-vehicle-fuelAnalytics`
                              : `card text-center mb-2`
                          }
                          key={index}
                          onClick={() =>
                            handleVehicleNo({
                              value: vehicle.vehicleNo,
                              label: vehicle.vehicleNo,
                            })
                          }
                        >
                          <div className="card-body">
                            <div className="vehicle-details">
                              <div className="d-flex">
                                <RiTruckLine style={{ marginRight: "5px" }} />
                                <p className="mb-1 mr-1 vehicle-no">
                                  {vehicle?.vehicleNo}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-1 vehicle-no">
                                  <Translate>FuelLevel</Translate>:{" "}
                                  {vehicle?.fuel}L/{vehicle?.maxTankCapacity}L
                                </p>
                              </div>
                              <ProgressBar
                                value={vehicle?.fuel}
                                maxValue={vehicle.maxTankCapacity}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-5 mt-1 dropdown-analytics">
                  <div className="dropdown-analytics-child">
                    <Select
                      styles={customStyles}
                      options={vehicleOption}
                      onChange={(e) => handleVehicleNo(e)}
                      value={vehicle}
                      maxMenuHeight={150}
                      placeholder="Select your vehicle"
                    />
                  </div>
                  <div style={{ width: "180px" }}>
                    <DateRangePicker
                      className="custom-date-range"
                      initialSettings={initialSettings}
                      onApply={handleApplyDate}
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="card custom-card mt-1">
                    <div style={{ padding: "10px" }}>
                      {vehicleList.length > 0 ? (
                        <MapFuelFill
                          locations1={fuelLocation}
                          selectVehicleNo={selectVehicleNo}
                        />
                      ) : (
                        <div className="card-body">No records found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xs-8 mt-3">
              {isLoading ? (
                <div
                  className="card"
                  style={{ width: "100%", height: "30%", marginBottom: "10px" }}
                >
                  <Loading />
                </div>
              ) : (
                <Card className="mb-3">
                  <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {`${selectVehicleNo}`}
                      </p>
                      <div></div>
                    </div>
                  </Card.Header>

                  <Card.Body className="p-0 scrollable-card-body">
                    <div className="pi-chart-wrapper">
                      <ApexChart
                        series={Lineseris}
                        options={LineOptions}
                        type={"area"}
                        height={240}
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="mb-2" style={{ width: "20%" }}>
                  <Select
                    styles={customStyles}
                    options={reportoption}
                    onChange={(e) => handleReport(e)}
                    value={reportSelect}
                    maxMenuHeight={150}
                    placeholder="Select your vehicle"
                  />
                </div>
              </div>
              {isLoading ? (
                <div className="card" style={{ width: "100%", height: "30%" }}>
                  <Loading />
                </div>
              ) : (
                <div>
                  {finalFuelData.length === 0 && (
                    <div>
                      {" "}
                      <p> No filling for this duration </p>
                      <hr
                        style={{
                          height: "2px",
                          backgroundColor: " #4CAF50",
                          marginTop: "-10px",
                        }}
                      />
                    </div>
                  )}
                  {finalFuelData.length > 0 && (
                    <div className="report-results mt-4">
                      <Card className="mb-3">
                        <Card.Header>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0" style={{ fontSize: "14px" }}>
                              Fuel Analysis
                            </p>
                            <div>
                              <a
                                className="cursor-pointer"
                                onClick={handleDownload}
                                disabled={downloadLoading}
                              >
                                <SiMicrosoftexcel
                                  style={{ color: "green" }}
                                  size={"20px"}
                                />
                              </a>
                            </div>
                          </div>
                        </Card.Header>

                        <Card.Body className="p-0 scrollable-card-body">
                          <ResponsiveTable data={finalFuelData} />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FuelTheft;
