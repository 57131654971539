import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf } from "react-icons/fa6";
import moment from "moment";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ResponsiveTable from "../../components/ResponsiveTable";
import { SweetAlert } from "../../helpers/helper";
import Translate from "../../components/Translate";
import { get, post } from "../../components/api";
import "./styles.css";

const FleetReportDetails = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [dateSelection, setDateSelection] = useState("day");
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [vehicles, setVehicles] = useState([]);
  const [downloadReportLink, setDownloadReportLink] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [fleetReportData, setFleetReportData] = useState("");
  const [reportTitle, setReportTitle] = useState("");
  // const [selectedOption, setSelectedOption] = useState({
  //   value: "report",
  //   label: <Translate>selectReport</Translate>,
  // });
  const [selectedVehicle, setSelectedVehicle] = useState({
    value: "vehicle",
    label: <Translate>selectVehicle</Translate>,
  });
  const [selectedFewRange, setSelectedFewRange] = useState({
    label: "Today",
    value: "today",
  });
  const [apiParams, setApiParams] = useState(null);
  const daterangepickerRef = useRef(null);
  const [startDate, setStart] = useState(moment().startOf("day").toDate());
  const [endDate, setEnd] = useState(moment().toDate());
  const [startDateWithTime, setStartDateWithTime] = useState(moment().toDate());
  const [endDateWithTime, setEndDateWithTime] = useState(moment().toDate());
  const today = moment().endOf("day");
  const now = moment();
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "rgba(242, 243, 244, 0.5)",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "0.8rem",
    }),
  };
  const ftmUserData = JSON.parse(localStorage.getItem("ftmUserData"));
  const userid = ftmUserData.id;
  const maxSpan = {
    days: 30,
  };
  const initialSettings = {
    startDate: startDate,
    endDate: endDate,
    timePicker: true,
    timePicker24Hour: false,
    maxDate: today.toDate(),
    maxTime: now.format("HH:mm"),
    maxSpan: maxSpan,
    locale: {
      format: "MMM D, YYYY HH:mm", // 12-hour format
    },
    opens: "left",
    ranges: {
      Today: [moment().startOf("day"), moment()],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [moment().subtract(6, "days").startOf("day"), moment()],
      "Last 30 Days": [moment().subtract(29, "days").startOf("day"), moment()],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month").startOf("day"),
        moment().subtract(1, "month").endOf("month").endOf("day"),
      ],
    },
  };

  const handleApply = (event, picker) => {
    const selectedStart = picker.startDate;
    const selectedEnd = picker.endDate;

    if (selectedEnd.isBefore(selectedStart.clone().add(1, "months"))) {
      setStart(selectedStart);
      setEnd(selectedEnd);
      const startDateTime = selectedStart.toISOString();
      const endDateTime = selectedEnd.toISOString();
      setStartDateWithTime(startDateTime);
      setEndDateWithTime(endDateTime);
      console.log(startDateWithTime);
      console.log(endDateWithTime);
    } else {
      SweetAlert(
        "Please select a date range within one month from the start date.",
        true
      );
    }
  };

  useEffect(() => {
    handleDateSelection(slug);
  }, [slug]);

  useEffect(() => {
    const getVehicles = async () => {
      const vehiclesData = await get("/admin/vehicles-list");
      if (vehiclesData.status === 200) {
        const vehicleList = [];
        if (vehiclesData.data.length > 0) {
          vehiclesData.data.map((vehicle) => {
            vehicleList.push({
              label: vehicle.vehicleNo,
              value: vehicle._id,
            });
          });
        }
        setVehicles(vehicleList);
      } else {
        setVehicles([]);
      }
    };
    getVehicles();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    // handleFewRange(selectedFewRange);
  };

  useEffect(() => {
    handleFewRange(selectedFewRange);
  }, [selectedVehicle]);

  const handleReportParams = async () => {
    if (selectedVehicle.value == "vehicle") {
      SweetAlert("Please select vehicle.", true);
      return false;
    }
    setLoading(true);
    let reportParams = {};
    reportParams.vehicleNo = selectedVehicle.label;
    console.log(slug);
    switch (slug) {
      case "today-report":
        reportParams.date = moment(date).format("DD/MM/YYYY");
        reportParams.reportType = "24Hours";
        break;
      case "DaywiseReport":
        // reportParams.date = moment(date).format("DD/MM/YYYY");
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.reportType = "DaywiseReport";
        break;
      case "Monthly":
        reportParams.date = moment(date).format("DD/MM/YYYY");
        // reportParams.startDate = moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        // reportParams.endDate = moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.reportType = "Monthly";
        break;
      case "fuel-consuption-report":
        reportParams.reportType = "fuelConsumption";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "idle-duration-report":
        reportParams.reportType = "idle";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "geofence-report":
        reportParams.reportType = "geofence";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "ignition-report":
        reportParams.reportType = "ignition";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "ignition-on-off-report":
        reportParams.reportType = "ignitiononoff";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "stopage-report":
        reportParams.reportType = "stopagereport";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "Network-Report":
        reportParams.reportType = "networkReport";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        break;
      case "overspeeding-report":
        reportParams.reportType = "overspeed";
        reportParams.startDate =
          moment(startDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.endDate =
          moment(endDateWithTime).format("DD/MM/YYYY HH:mm");
        reportParams.userid = userid;
        break;
      case "Summary-Report":
        reportParams = apiParams;
        break;
      default:
        break;
    }
    await downloadReport(reportParams);
  };

  const downloadReport = async (reportParams) => {
    try {
      setLoading(true);
      const reportsData = await post("/admin/generate-report", reportParams);
      if (reportsData.status === 200) {
        const updatedArray = await addVehicleInfoToReports(
          reportsData.data.reportData,
          reportsData.data.VehicleNo
        );
        setFleetReportData(updatedArray);
        setDownloadReportLink(reportsData.data.reportLink);
        setPdfLink(reportsData.data.reportLinkpdf);
      } else {
        setFleetReportData([]);
        SweetAlert(reportsData.message, true);
      }
    } catch (error) {
      console.error("Error fetching and processing reports:", error);
      setFleetReportData([]);
      SweetAlert("An error occurred while generating the report.", true);
    } finally {
      setLoading(false);
    }
  };

  // add values to array
  const addVehicleInfoToReports = async (reportData, vehicleNo) => {
    return reportData.map((report) => ({
      vehicleNo: vehicleNo,
      ...report,
    }));
  };

  const handleFewRange = (selectedFewRange) => {
    setSelectedFewRange(selectedFewRange);

    const today = moment();
    const reportType = "SummaryReport";
    let date, SubreportType, startDate, endDate;

    switch (selectedFewRange.value) {
      case "today":
        startDate = today.clone().startOf("days").format("DD/MM/YYYY HH:mm");
        endDate = today.clone().format("DD/MM/YYYY HH:mm");
        date = `${startDate} - ${endDate}`;
        SubreportType = "SummaryReport7Days";
        break;
      case "yesterday":
        startDate = today
          .clone()
          .add(-1, "day")
          .startOf("days")
          .format("DD/MM/YYYY HH:mm");
        endDate = today
          .clone()
          .add(-1, "day")
          .endOf("days")
          .format("DD/MM/YYYY HH:mm");
        date = `${startDate} - ${endDate}`;
        SubreportType = "SummaryReport7Days";
        break;
      case "last7days":
        // Format date range for the last 7 days
        startDate = today
          .clone()
          .subtract(6, "days")
          .startOf("days")
          .format("DD/MM/YYYY HH:mm");
        // endDate = today.format('DD/MM/YYYY');
        endDate = today.clone().format("DD/MM/YYYY hh:mm");
        date = `${startDate} - ${endDate}`;
        SubreportType = "SummaryReport7Days";
        break;
      default:
        break;
    }
    const params = {
      vehicleNo: selectedVehicle.label,
      startDate,
      endDate,
      date,
      SubreportType,
      reportType,
    };

    setApiParams(params);
    // Make API call here
  };

  const handleDateSelection = (reportType) => {
    switch (reportType) {
      case "today-report":
        setDateSelection("day");
        setReportTitle("Today Report");
        break;
      case "DaywiseReport":
        setDateSelection("range");
        setReportTitle("Daily Report");
        break;
      case "Monthly":
        setDateSelection("month");
        setReportTitle("Monthly Report");
        break;
      case "fuel-consuption-report":
        setReportTitle("Fuel Consuption Report");
        setDateSelection("range");
        break;
      case "idle-duration-report":
        setReportTitle("Idle Duration Report");
        setDateSelection("range");
        break;
      case "geofence-report":
        setReportTitle("Geofence Report");
        setDateSelection("range");
        break;
      case "ignition-report":
        setReportTitle("Ignition Report");
        setDateSelection("range");
        break;
      case "ignition-on-off-report":
        setReportTitle("Ignition On OFF Report");
        setDateSelection("range");
        break;
      case "stopage-report":
        setReportTitle("stopage report");
        setDateSelection("range");
        break;
      case "Network-Report":
        setReportTitle("neteork report");
        setDateSelection("range");
        break;
      case "overspeeding-report":
        setReportTitle("Overspeeding Report");
        setDateSelection("range");
        break;
      case "Summary-Report":
        setReportTitle("Summary Report");
        setDateSelection("FewRange");
        break;
      default:
        setDateSelection("day");
        break;
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const response = await fetch(
        `${downloadReportLink}?nocache=${new Date().getTime()}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        }
      );
      console.log(downloadReportLink);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const regex = /excel(.+?)\.xlsx/;
      const match = downloadReportLink.match(regex);
      let fileName = "";
      if (match && match[1]) {
        fileName = match[1];
        console.log(fileName);
      } else {
        console.log("No match found");
      }
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadLoading(false);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  const handleDownloadPDF = async () => {
    try {
      setDownloadLoading(true);
      const response = await fetch(
        `${pdfLink}?nocache=${new Date().getTime()}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const regex = /pdf_(.+?)\.pdf/;
      const match = pdfLink.match(regex);
      let fileName = "";
      if (match && match[1]) {
        fileName = match[1];
        console.log(fileName);
      } else {
        console.log("No match found");
      }
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setDownloadLoading(false);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const fewRange = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "last7days", label: "Last 7 Days" },
  ];

  return (
    <div className="report-details">
      <Card className="mt-3">
        <Card.Body>
          <div className="search-component">
            <div className="formInputWrapper">
              <p className="reportsFormLabel">
                <Translate>selectVehicle</Translate>
              </p>
              <div className="selectWrapperFull">
                <Select
                  value={selectedVehicle}
                  onChange={(e) => handleChange(e, "vehicle")}
                  options={vehicles}
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="formInputWrapper">
              <p className="reportsFormLabel">
                <Translate>selectDate</Translate>
              </p>
              <div className="selectWrapperFull">
                {dateSelection == "day" ? (
                  <>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      maxDate={Date.now()}
                      dateFormat={"dd/MM/yyyy"}
                    />
                  </>
                ) : dateSelection == "month" ? (
                  <>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      dateFormat={"MM/yyyy"}
                      maxDate={Date.now()}
                      showMonthYearPicker
                    />
                  </>
                ) : dateSelection == "range" ? (
                  <>
                    <DateRangePicker
                      className="custom-date-range"
                      ref={daterangepickerRef}
                      initialSettings={initialSettings}
                      onApply={handleApply}
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </>
                ) : (
                  <>
                    <Select
                      value={selectedFewRange}
                      onChange={handleFewRange}
                      options={fewRange}
                      styles={customStyles}
                      placeholder="Select a time period"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="formInputWrapper report-btn-parent">
              <Button
                type="btn"
                className="btn report-search-btn"
                onClick={handleReportParams}
                disabled={loading}
              >
                Search
              </Button>{" "}
              {loading && (
                <div className="ml-2">
                  <Spinner
                    size="md"
                    animation="border"
                    className="spinner-primary"
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      {Array.isArray(fleetReportData) && fleetReportData.length > 0 && (
        <div className="report-results mt-4">
          <Card className="mb-3">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0" style={{ fontSize: "14px" }}>
                  {reportTitle}
                </p>
                <div>
                  <a
                    onClick={handleDownload}
                    disabled={downloadLoading}
                    className="cursor-pointer"
                  >
                    <SiMicrosoftexcel
                      style={{ color: "green", marginRight: "15px" }}
                      size={"20px"}
                    />
                  </a>
                  <a
                    onClick={handleDownloadPDF}
                    disabled={downloadLoading}
                    className="cursor-pointer"
                  >
                    <FaFilePdf style={{ color: "#2A7C76" }} size={"20px"} />
                  </a>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0 scrollable-card-body">
              <ResponsiveTable data={fleetReportData} />
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default FleetReportDetails;
