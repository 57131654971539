import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Lightbox } from "react-modal-image";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { get } from "../../components/api";
import Bannar from "../../assets/profile/Bannar.png";
import profileAvtar from "../../assets/profile/sample_profile.png";
import "./index.css";
import Translate from "../../components/Translate";

const Profile = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [profileData, setProfileData] = useState("");
  const [showAdharCard, setShowAdharCard] = useState(false);
  const [showPanCard, setShowPanCard] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await get("/admin/profile/edit/" + params.id);
        if (response.status == 200) {
          let temp = { ...response.data };
          temp.id = response?.data?._id;
          localStorage.setItem("ftmUserData", JSON.stringify(temp));
          // document.getElementById("profile-img").src = "";
          //   document.getElementById("profile-img").src = response?.data?.photo;
          setProfileData(response.data);
        } else {
          setProfileData("");
          navigate("/dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfileData();
  }, []);

  const editprofile = () => {
    if (params.id) {
      navigate("/profile/edit/" + params.id);
    }
  };

  return (
    <div className="profile-details-page p-4">
      <div className="text-end mb-3">
        <button
          className="btn btn-primary custom-submit-btn"
          style={{ width: "130px", height: "40px" }}
          onClick={editprofile}
        >
          Edit Profile
        </button>
      </div>
      <Card className="custom-card">
        <Card.Img
          variant="top"
          src={Bannar}
          style={{ width: "100%", height: "150px", position: "relative" }}
        />
        <Card.Body>
          <div className="rounded-circle-profile rounded-circle-profile-pic mb-4">
            <img
              src={profileData.photo ? profileData.photo : profileAvtar}
              alt=""
            />
          </div>
          <p className="text-left ml-150 profile-title">
            {profileData.ownerName ? profileData.ownerName : "-"}
          </p>
        </Card.Body>
      </Card>
      <Card className="custom-card mt-4">
        <Card.Header className="text-left">
          <Translate>Basic Info</Translate>
        </Card.Header>
        <Card.Body>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Gender</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.gender ? profileData.gender : "-"}
              </span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>DOB</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.dob
                  ? moment(profileData.dob).format("DD/MM/YYYY")
                  : "-"}
              </span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Email</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <a href={`mailto:${profileData.email}`} className="info-value">
                {profileData.email ? profileData.email : "-"}
              </a>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Phone</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <a href={`tel:${profileData.mobileNo}`} className="info-value">
                {profileData.mobileNo ? `+91${profileData.mobileNo}` : "-"}{" "}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Location</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.address ? profileData.address : ""}
              </span>
            </div>
          </div>
        </Card.Body>
        <Card.Header className="text-left">
          <Translate>Other Details</Translate>
        </Card.Header>
        <Card.Body>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Adhar Card</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              {showAdharCard && (
                <div>
                  <Lightbox
                    medium={profileData.adharPhoto}
                    large={profileData.adharPhoto}
                    alt="Adhar Card"
                    hideDownload
                    hideZoom
                    onClose={() => setShowAdharCard(false)}
                  />
                </div>
              )}
              <a
                rel="noopener noreferrer"
                className="info-value cursor-pointer"
                onClick={() => setShowAdharCard(true)}
              >
                Click to view
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Pan Card</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              {showPanCard && (
                <div>
                  <Lightbox
                    medium={profileData.panPhoto}
                    large={profileData.panPhoto}
                    alt="Pan Card"
                    hideDownload
                    hideZoom
                    onClose={() => setShowPanCard(false)}
                  />
                </div>
              )}
              <a
                rel="noopener noreferrer"
                className="info-value cursor-pointer"
                onClick={() => setShowPanCard(true)}
              >
                Click to view
              </a>
            </div>
          </div>
        </Card.Body>
        <Card.Header className="text-left">
          <Translate>Company Details</Translate>
        </Card.Header>
        <Card.Body>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Name</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.name ? profileData.name : "-"}
              </span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>Website</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.companyWeb ? profileData.companyWeb : "-"}
              </span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 text-left">
              <span className="info-title">
                <Translate>address</Translate> :
              </span>
            </div>
            <div className="col-md-8 text-left">
              <span className="info-value">
                {profileData.companyAddress ? profileData.companyAddress : "-"}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Profile;
