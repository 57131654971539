import React, { useCallback, useEffect, useState } from "react";
import {
  Circle,
  Polygon,
  DrawingManager,
  GoogleMap,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import "../Geofence/indexGeo.css";
import { SweetAlert } from "../../helpers/helper";
import AutocompleteInput from "../../components/AutocompleteInput";
import { post } from "../../components/api";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import View from "../../assets/emergency_services/view.svg";
import SVG from "react-inlinesvg";
import Checkbox from "../../components/DropdownCheckbox";

const libraries = ["places", "drawing"];

const GeofenceEdit = () => {
  const { state } = useLocation();
  const [geofenceName, setGeofenceName] = useState("");
  const [geofenceType, setGeofenceType] = useState("");
  const [location, setLocation] = useState("");
  const [paths, setPaths] = useState([]);
  const [sendPaths, setSendPaths] = useState({});
  const [radius, setRadius] = useState("");
  const [searchType, setSearchType] = useState("place");
  const [mapType, setMapType] = useState("roadmap");
  const [latitude, setLatitude] = useState(23.020561);
  const [longitude, setLongitude] = useState(72.583612);
  const [states, setStates] = useState({ drawingMode: "" });
  const [checkBoxOption, setCheckBoxOption] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });
  const handleClear = () => {
    setPaths([]);
    setSendPaths([]);
    setGeofenceName("");
    setGeofenceType("");
    setLocation("");
    setRadius("");
    setLatitude(23.020561);
    setLongitude(72.583612);
    setStates({ drawingMode: "" });
  };
  const convertCoordinatesToPaths = (coordinates) => {
    const set = coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
    console.log(set);
    return set;
  };

  useEffect(() => {
    fetchVehicle();
  }, []);

  const fetchVehicle = async () => {
    try {
      const apiResponse = await post("/admin/alerts/Vehicle");
      if (apiResponse?.status === 200) {
        const option = apiResponse.dataId.map((item) => ({
          label: item.vehicleNo,
          value: item.vehicleId,
        }));
        setCheckBoxOption(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state?.geofence?.paths?.center?.length > 0) {
      // Set sendPaths with center and radius information
      setSendPaths({
        center: state.geofence.paths.center,
        radius: state.geofence.paths.radius,
      });

      // Extract radius and set paths with center information
      const radius = state.geofence.paths.radius;
      setPaths([
        {
          lat: state.geofence.paths.center[1],
          lng: state.geofence.paths.center[0],
          radius,
        },
      ]);
    } else {
      // Set sendPaths with coordinates information
      setSendPaths({ coordinates: state?.geofence?.paths?.coordinates });

      // Convert coordinates to paths and set them
      setPaths(
        convertCoordinatesToPaths(state?.geofence?.paths?.coordinates[0])
      );
    }
    setVehicles(state.geofence.vehiclesAllowed);
    setGeofenceName(state.geofence.geofencename);
    setGeofenceType(state.geofence.geofencetype);
    setLocation(state?.geofence?.location);
    console.log(state?.geofence?.paths.radius);
    setRadius(state?.geofence?.paths?.radius);
    setLatitude(state.geofence.latitude);
    setLongitude(state.geofence.longitude);
    setStates({ drawingMode: state.geofence.geofencetype });
  }, [state]);
  useEffect(() => {
    if (searchType === "coordinates") {
      setLatitude(state.geofence.latitude);
      setLongitude(state.geofence.longitude);
    }
  }, [searchType]);

  const mapContainerStyle = {
    // width: window.innerWidth < 850 ? "100%" :'600px',
    width: "100%",
    height: window.innerWidth < 850 ? "550px" : "100%",
  };
  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    trafficControlOptions: {
      mapTypeIds: ["roadmap", "satellite"],
      // style: window.google.maps.TrafficStyle,
    },
  };
  const toggleMapType = (type) => {
    if (type == "view-type") {
      const newMapType = mapType === "roadmap" ? "satellite" : "roadmap";
      setMapType(newMapType); // Update mapType state
    }
  };
  const options = {
    drawingControl: true,
    drawingControlOptions: {
      position: "none",
      drawingMode: [geofenceType],
    },
    polygonOptions: {
      fillColor: "#2196F3",
      strokeColor: "#2196F3",
      fillOpacity: 0.5,
      strokeWeight: 2,
      Clickable: true,
      // editable:true,
      draggable: false,
      zindex: 1,
    },

    circleOptions: {
      fillColor: "#2196F3",
      strokeColor: "#2196F3",
      fillOpacity: 0.5,
      strokeWeight: 2,
      Clickable: true,
      editable: true,
      draggable: false,
      zindex: 1,
    },
  };
  const onPolygonComplete = useCallback(function onPolygonComplete(poly) {
    const polyArray = poly.getPath().getArray();
    let paths = [];
    let sendPaths = [];
    polyArray.forEach((path) => {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    polyArray.forEach((path) => {
      sendPaths.push([path.lng(), path.lat()]);
    });
    const finalPaths = [...paths, paths[0]];
    const finalsendPaths = { coordinates: [...sendPaths, sendPaths[0]] };
    setPaths(finalPaths);
    setSendPaths(finalsendPaths);
    poly.setMap(null);
  }, []);

  const onCircleComplete = useCallback((circle) => {
    const center = circle.getCenter();
    let radius = Math.floor(circle.getRadius());
    if (radius > 2000) {
      radius = 2000;
    } else if (radius < 200) {
      radius = 200;
    }
    setPaths([{ lat: center.lat(), lng: center.lng(), radius }]);
    setSendPaths({ center: [center.lng(), center.lat()], radius: radius });
    circle.setMap(null);
    setRadius(radius);
  }, []);
  // Handle change event
  const handleChange = (event) => {
    setPaths([]);
    setGeofenceType(event.target.value);
    setStates({ drawingMode: event.target.value });
  };

  const handleRadiusChange = (event) => {
    let newRadius = Math.floor(parseFloat(event.target.value));
    if (newRadius < 200) {
      newRadius = 200;
    } else if (newRadius > 2000) {
      newRadius = 2000;
    }
    setRadius(newRadius);
    if (paths?.length > 0) {
      setPaths([{ ...paths[0], radius: newRadius }]);
      setSendPaths({ center: sendPaths.center, radius: newRadius });
      // setSendPaths( sendPaths.radius = newRadius );
    }
  };

  const toggleChange = () => {
    if (searchType === "place") {
      setSearchType("coordinates");
    } else if (searchType === "coordinates") {
      setSearchType("place");
    }
  };

  const handlePlaceSelected = (place) => {
    setLatitude(place.geometry.location.lat());
    setLongitude(place.geometry.location.lng());
    setLocation(place.formatted_address);
  };

  const handleSubmit = async (e) => {
    if (geofenceName === "" || geofenceName === undefined) {
      e.preventDefault();
      SweetAlert("Please add Geofence name", true);
    } else if (
      (searchType === "place" && location === "") ||
      location === undefined
    ) {
      e.preventDefault();
      SweetAlert("Please add location", true);
    } else if (
      (searchType === "coordinates" && latitude === 0) ||
      longitude === 0
    ) {
      e.preventDefault();
      SweetAlert("Please add coordinates", true);
    } else if (paths.length === 0) {
      e.preventDefault();
      SweetAlert("Please Select Geofence Type", true);
    } else {
      e.preventDefault();
      if (geofenceName?.length >= 4 && geofenceName?.length <= 20) {
        if (searchType === "place" && location?.length > 4) {
          //add-geofence
          try {
            const apiResponse = await post("admin/edit-geofence", {
              geofenceId: state.geofence._id,
              geofencename: geofenceName,
              geofencetype: geofenceType,
              searchtype: searchType,
              paths: sendPaths,
              latitude: latitude,
              longitude: longitude,
              location: location,
              vehicles: vehicles,
            });
            if (apiResponse?.status === 200) {
              SweetAlert(apiResponse?.message, false);
              handleClear();
              navigate("/geofence");
            } else if (apiResponse?.status === 201) {
              SweetAlert(apiResponse?.message, true);
            }
          } catch (error) {
            console.log(error);
          }
        } else if (searchType === "coordinates" && latitude && longitude) {
          try {
            const apiResponse = await post("admin/edit-geofence", {
              geofenceId: state.geofence._id,
              geofencename: geofenceName,
              geofencetype: geofenceType,
              searchtype: searchType,
              paths: sendPaths,
              latitude: latitude,
              longitude: longitude,
              location: location,
              vehicles: vehicles,
            });
            if (apiResponse?.status === 200) {
              SweetAlert(apiResponse?.message, false);
              handleClear();
              navigate("/geofence");
            } else if (apiResponse?.status === 201) {
              SweetAlert(apiResponse?.message, true);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          SweetAlert(` All fields are required`, true);
        }
      } else {
        if (geofenceName?.length < 4) {
          SweetAlert("geofence Name shold have atleast 4 letters", true);
        } else if (geofenceName?.length > 20) {
          SweetAlert(
            "geofence name should not have more than 20 letters.",
            true
          );
        }
      }
    }
  };

  const handleVehicleChange = (selected) => {
    setVehicles(selected.map((option) => option.value));
  };
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  
  return (
    <div className="Geofence-main-container">
      <div className="form-container card p-3">
        <h6 className="text-left p-3">Edit Geofence</h6>
        <div className="containerGeofence">
          <label className="required custom-lable">Place</label>
          <label className="switch">
            <input type="checkbox" value={searchType} onChange={toggleChange} />
            <span className="slider" />
          </label>
          <label className="required custom-lable">Coordinates</label>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group text-left">
            <label className="required custom-lable">Geofence Name</label>
            <input
              placeholder="Geofence Name"
              type="text"
              name="geofenceName"
              className="form-control mb-1"
              autoComplete="off"
              value={geofenceName}
              onChange={(e) => setGeofenceName(e.target.value)}
              maxLength={20}
            />
          </div>

          {searchType === "place" && (
            <div className="text-left form-group">
              <label className="required custom-lable">Location</label>
              {console.log(location)}
              <AutocompleteInput
                name="Location"
                value={location}
                onPlaceSelected={handlePlaceSelected}
                className="userInput"
              />
            </div>
          )}
          {searchType === "coordinates" && (
            <div className="text-left form-group">
              <label className="required custom-lable">Latitude</label>
              <input
                placeholder="Latitude"
                type="number"
                name="Latitude"
                className="form-control mb-3"
                autoComplete="off"
                value={latitude}
                onChange={(e) => setLatitude(parseFloat(e.target.value))}
              />
              <label className="required custom-lable">Longitude</label>
              <input
                placeholder="Longitude"
                type="number"
                name="Longitude"
                className="form-control mb-3"
                autoComplete="off"
                value={longitude}
                onChange={(e) => setLongitude(parseFloat(e.target.value))}
              />
            </div>
          )}

          <div className="text-left form-group">
            <p className="custom-lable mt-1 mb-2">
              Choose Geometry of Geofence
            </p>
            <label className="radio-label custom-lable">
              <input
                type="radio"
                name="myRadio"
                value="circle"
                checked={geofenceType === "circle"}
                onChange={handleChange}
              />
              Circle
            </label>
            <label className="radio-label custom-lable">
              <input
                type="radio"
                name="myRadio"
                value="polygon"
                checked={geofenceType === "polygon"}
                onChange={handleChange}
              />
              Polygon
            </label>
          </div>

          {paths?.length === 1 && (
            <div className="text-left form-group">
              <input
                type="number"
                name="radius"
                value={radius}
                placeholder={radius}
                onChange={handleRadiusChange}
                className="form-control"
                min={200}
                max={2000}
              />
            </div>
          )}
          <div
            className="form-group text-left"
            style={{ marginBottom: "10px" }}
          >
            <label className="required custom-lable">Select Vehicles</label>
            <Checkbox
              options={checkBoxOption}
              value={checkBoxOption.filter((option) =>
                vehicles.includes(option.value)
              )}
              // Show currently selected alert types
              onChange={handleVehicleChange} // Add onChange handler for alert types
              name={"Vehicles"}
            />
          </div>
          <div
            className="card-footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Link
                to="/geofence"
                style={{
                  border: "none",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <button type="submit" className="btn-geo-create">
                  Back
                </button>
              </Link>
            </div>
            <div className="button-group">
              <button type="submit" className="btn-geo-create">
                Update
              </button>
              <button
                type="button"
                className="btn-geo-secondry"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        className="map-container border-1 card"
        style={{ position: "relative", padding: "10px" }}
      >
        {/* <div className="google-map-parent"> */}
        <GoogleMap
          zoom={latitude !== 23.020561 ? 13 : 8}
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: latitude || paths[0]?.lat || 23.020561,
            lng: longitude || paths[0]?.lng || 72.583612,
          }}
          options={mapOptions}
          onClick={
            searchType === "coordinates"
              ? (e) => {
                  setLatitude(e.latLng.lat());
                  setLongitude(e.latLng.lng());
                }
              : undefined
          }
        >
          {latitude !== 23.020561 ? (
            <Marker
              position={{
                lat: parseFloat(paths[0]?.lat || latitude || 22.9),
                lng: parseFloat(paths[0]?.lng || longitude || 72.5),
              }}
            ></Marker>
          ) : (
            ""
          )}
          {/* <Marker
            position={{
              lat: parseFloat(latitude || paths[0]?.lat || 22.9),
              lng: parseFloat(longitude || paths[0]?.lng || 72.5 ),
            }}>
            </Marker> */}
          {paths?.length === 0 ? (
            <DrawingManager
              drawingMode={states.drawingMode}
              options={options}
              editable
              onPolygonComplete={onPolygonComplete}
              onCircleComplete={onCircleComplete}
              // onMouseUp={onEdit}
              // onDragEnd={onEdit}
            />
          ) : paths?.length === 1 ? (
            <Circle
              center={{ lat: paths[0]?.lat, lng: paths[0]?.lng }}
              radius={paths[0]?.radius}
              options={{
                fillColor: "#2496F3",
                strokeColor: "#2496F3",
                fillOpacity: 0.5,
                strokeWeight: 2,
              }}
              // editable
            />
          ) : (
            <Polygon
              options={{
                fillColor: "#2496F3",
                strokeColor: "#2496F3",
                fillOpacity: 0.5,
                strokeWeight: 2,
              }}
              // editable
              path={paths}
              // onLoad={onLoad}
              // onUnmount={onUnmount}
            />
          )}
        </GoogleMap>
        {/* </div> */}
        <div className="map-custom-icons">
          <div
            className={
              mapType == "roadmap"
                ? `image-container mt-3 mb-2`
                : `image-container-active mt-3 mb-2`
            }
            onClick={() => toggleMapType("view-type")}
          >
            <SVG src={View} width={"20px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceEdit;
